import React, { useState } from 'react';
import axios from "axios";
import './index.css';

const Login = () => {

    const [user, setUser] = useState();
    const [ra, setRa] = useState();

    const getEmail = () => {
        if (ra) {
            axios.get('https://integracaopersonetotvs-ktjzguamxq-ue.a.run.app/aluno/' + ra)
                .then((res) => {
                    setUser(res.data[0])
                    //console.log(res.data[0].email)
                })
        } else {
            setUser('')
        }
    }

    return (
        <main>
            <div className="login-page">
                <div className="form">
                    <img className="logo" src="logo.png" />
                    <br />
                    <div>
                        <h2>Seja bem-vindo(a) ao Canvas da Faculdade Ari de Sá</h2>
                        <p>Para acessar o Canvas utilize seu e-mail institucional (@fas.edu.br) e sua senha. Lembramos, que para acessar Canvas, TOTVS, Wifi, NPJe e demais serviços, sempre utilize seu e-mail institucional e sua senha.</p>
                        <button className="botao-acessar" onClick={() => {window.location.href="https://faculdadearidesa.instructure.com/login/microsoft"}}>Acessar o Canvas</button>

                        <ol>
                            <li className="form-item">
                                Para alterar sua senha atual <a href="https://servicos.aridesa.com.br/alterarsenhas/usuario/alterar" target="_blank">clique aqui</a>.
                            </li>
                            <li className="form-item">
                                Se você não sabe sua senha atual, você pode criar uma nova <a href="https://servicos.aridesa.com.br/forgot-password/fas" target="_blank">clicando aqui</a> e informando seu CPF e data de nascimento.
                            </li>
                            <li className="form-item">
                                Após a troca da senha, por favor, aguarde uns 5 (cinco) minutos antes de acessar os sistemas.
                            </li>
                        </ol>
                    </div>
                    {/*<div className="login-form">
                        <input type="text" placeholder="Digite seu RA" onChange={e => setRa(e.target.value)} />
                        <input type="text" readOnly placeholder="Seu email irá aparecer aqui" value={user?.email}/>

                        <button id="divleft" type="submit" onClick={getEmail}>Consultar e-mail</button>
                        <a href="https://faculdadearidesa.instructure.com/login/microsoft"><button>Acessar o Canvas</button></a>
                    </div>*/}
                    <p className="message">Faculdade Ari de Sá</p>
                </div>
            </div>
        </main>
    )
}

export default Login;