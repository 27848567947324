import './App.css';
import Login from './components/Login';

function App() {
  return (
    <>
      <Login />
    </>
  );
}
export default App;
